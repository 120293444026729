.wrapper {
    flex-basis: 50%;
    max-width: 50%;
    width: 540px;

    &.large {
        flex-basis: 100%;
        max-width: 100%;
        width: 1080px;
    }
}

.inner {
    padding: 24px;
    margin: 12px;
    max-width: 100%;
    height: calc(100% - 24px);
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: 900;
    font-size: 20px;
    flex-grow: 1;
}

.titleWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.infoTip {}

.tag {
    background: transparent !important;
    color: grey;
    border-radius: 22Px;
}

.subtitle {}

.content {
    margin-top: 12px;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.more {
    padding: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid transparent;

    &:hover {
        border-color: #d9d9d9;
    }
}

@media (max-width: 1080px) {
    .wrapper {
        flex-basis: 100%;
        max-width: 100%;
    }

    .inner {
        margin: 0;
        margin-bottom: 12px;
    }
}