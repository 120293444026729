.name {
    font-size: 24px;
    margin-top: 6px;
}

.wrapper {
    display: flex;
}

.leftWrapper {
    flex-basis: 34%;
    height: fit-content;
    padding: 24px;
    // background-color: #f7f1eb;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid #e5e5e5;
    border-radius: 22px;

    .leftInner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.number {
    font-weight: 900;
}

.rightWrapper {
    flex-basis: 66%;
    padding: 24px;
    border: 1px solid #e5e5e5;
    border-radius: 22px;
    margin-left: 22px;
}
.rightTitle{
    display: flex;
    margin-bottom: 44px;
}
.feedTitle{
    flex-grow: 1;
}
.stats {
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
}

.date {
    color: grey;
    font-size: 14px;
}

.statElem {
    display: flex;
    align-items: center;
}

.timeline {
    margin-top: 34px;
}
.showmore{
    display: flex;
    justify-content: center;
}

@media (max-width: 980px) {

    .wrapper {
        flex-wrap: wrap;
    }

    .leftWrapper,
    .rightWrapper {
        flex-basis: 100%;
        max-width: 100%;
    }
    .rightWrapper {
        margin-left: 0px;
        margin-top: 22px;
    }
}