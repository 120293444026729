.wrapper {}

.inner {}

.list {
    margin-top: 12px;
}

.rank {
    display: flex;
    width: 100%;
    margin-bottom: 12px;
}

.rankLeft {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.avatar {}

.name {
    font-weight: bold;
    margin-left: 8px;
}

.score {
    font-weight: 900;
}
.rankNumber{
    width: 50px;
    color: grey;
}
