.addRewardWrapper{
    width: 680px;
    max-width: 100%;
    margin: auto;
}
.title{
    margin-bottom: 24px;
}
.back {
    padding-left: 0;
    margin-bottom: 12px;
}
