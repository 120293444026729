.wrapper {
    flex-basis: 33.3%;
}

.inner {
    margin: 12px;
    padding: 24px;
    min-width: 260px;
}

.value {
    font-size: 40px;
}

.label {
    font-weight: 900;
}

.suffix {
    font-size: 14px;
    margin-left: 10px;
}
@media (max-width: 1080px) {
    .inner:first-child {
        margin-left: 0px;
    }
}
@media (max-width: 660px) {
    .wrapper {
        flex-basis: 100%;
    }
    .inner {
        margin: 0px;
        margin-bottom: 12px;
    }
}