.title {
    margin-bottom: 18px;
    margin-left: 12px;
}

.subtitle {
    margin-bottom: 10px;
    margin-left: 12px;
}

.tableWrapper {
    margin-top: 44px;
    padding-bottom: 120px;
}

.filtersWrapper {
    margin-top: 44px;
}

.coloredColumn {
    background-color: #e3ffe3;
}

.rank {
    text-align: center;
}

.rankContent {
    font-size: 18px;
    font-weight: bold;
    color: #C0C0C0;

}

.rankContent0 {
    color: #e46e08;
}

.rankContent1 {
    color: #4089f7;
}

.rankContent2 {
    color: #964b00;
}

.rank0 {
    background-color: #ffe397;
}

.rank1 {
    background-color: #c3dbff;
}

.rank2 {
    background-color: #e9bc8f;
}

.whiteBackground {
    background: white
}

.avatar {
    position: relative;
}

.trophy {
    position: absolute;
    bottom: 10px;
    right: 24px;
    font-size: 18px;
    color: #ffac00;
}

.center {
    text-align: center;
}

.bold {
    font-weight: bold;
}