.list {
    display: flex;
    flex-wrap: wrap;
}

.title {
    margin-bottom: 18px;
}

.subtitle {
    margin-bottom: 10px;
}

.titleWrapper {
    display: flex;
    max-width: 100%;
    margin-bottom: 38px;

    .titleContent {
        flex-grow: 1;
        min-width: 340px;
    }

    .titleActions {}
}

.welcomeInner {
    display: flex;
}

.welcomeIcon {
    font-size: 52px;
    padding-right: 22px;
    padding-left: 22px;
}

.welcome {
    font-size: 52px;
    margin-bottom: 12px;
}

.welcomeWrapper {
    // margin-bottom: 44px;
    background: linear-gradient(90deg, #e5fee7, #f3fff4);
}

.welcomeText {
    line-height: 20px;
}
.noarchived{
    color: grey;
}
@media (max-width: 540px) {
    .titleWrapper {
        flex-wrap: wrap;
    }
    .welcomeInner {
        flex-wrap:wrap;
    }
    .welcomeIcon {
        padding-right: 0px;
        padding-left: 0px;
    }
    .welcome {
        font-size: 32px;
        margin-bottom: 12px;
    }
}