.button {
    font-weight: bold;
}

.hint {
    font-size: 12px;
    color: grey;
    text-align: center;
    margin-bottom: 6px;
}

.modalTitle {
    font-size: 22px;
}

.modalHint {
    color: grey;
    font-size: 14px;
    text-align: center;
}

.modalContent {
    text-align: center;
    font-size: 16px;
    margin-top: 22px;
}

.modalNameReward {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 28px;
}

.process {
    background-color: #e6e6e6;
    padding: 12px;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
}