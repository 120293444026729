.wrapper {
    width: 354px;
    min-height: 580px;
    height: 580px;
    max-width: 100%;
    border: 1px solid #e2e2e2;
    border-radius: 12px;
    margin-bottom: 18px;
    margin-right: 18px;
    position: relative;
    overflow: hidden;
}

.inner {
    padding: 30px;
    position: relative;
    height: 100%;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content {
    flex-grow: 1;
}

.picture {
    // background-color: grey;
    margin-bottom: 16px;
    min-height: 260px;
    width: 100%;
    border-radius: 6px;
    background-repeat: no-repeat;
    background-size: cover;
}

.title {
    font-weight: 900;
    font-size: 20px;
    font-family: 'silka', sans-serif;
    margin-bottom: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.description {
    margin-bottom: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}


.detailsLink {
    margin-bottom: 24px;
}

.adminActions {
    border-bottom: 1px solid #e2e2e2;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: white;
}

.edit {
    height: 24px !important;
    font-size: 14px !important;
}

.claimed {
    font-size: 14px;
}