.wrapper {
    margin-left: 12px;
    margin-right: 12px;
}

.inner {
    width: 1120px;
    max-width: 100%;
    margin: auto;
    padding-top: 44px;
    padding-bottom: 64px;
}