.app {}

.logo {
  height: 30px;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}