.title {
    margin-bottom: 18px;
    margin-left: 12px;
}

.secondaryTitle {
    margin-bottom: 18px;
    margin-left: 12px;
    margin-top: 24px;
}

.subtitle {
    margin-bottom: 10px;
    margin-left: 12px;
}

.vignettes {
    display: flex;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}

.wrapper {
    margin-top: 32px;
}

.dataWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
}

.paginate {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 6px;
    margin-top: 6px;
}

.hintAlert {
    border: none;
    font-size: 14px;
    color: grey;
    background: #eeeeee;

}

.titleWrapperVignette {
    display: flex;
    align-items: center;

    .titleLeft {
        flex-grow: 1;
    }

    .titleRight {
        a {
            font-size: 16px;
            font-weight: normal;
        }
    }
}

.topUsersValueName {
    font-weight: 900;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.topUserWrapper {
    margin-left: 18px;
    font-weight: bold;
    position: relative;
}

.topValueUsername {
    margin-left: 8px;
}

.topUsersIcon {
    font-weight: bold;
    // margin-right: 8px;
    margin-left: -10px;
    font-size: 18px;
    z-index: 2;
    position: absolute;
    bottom: -4px;
}

.topUsersAvatar {
    border: 4px solid gold;
    z-index: 1;
    position: relative;
}
.topUserNoData{
    color: #b8b8b8;
    font-style: italic;
    font-weight: 300;
    margin-left: 18px;
}
.TopUserViewLeaderboard{
    font-weight: 300;

}
.topUsersValueNameInner{
    flex-grow: 1;
}
.pieWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}
@media (max-width: 1100px) {}

@media (max-width: 1080px) {
    .vignettes {
        flex-wrap: wrap;
    }

    .title {
        margin-left: 0px;
    }

    .secondaryTitle {
        margin-left: 0px;
    }

    .subtitle {
        margin-left: 0px;
    }
}