.wrapper {
    display: flex;
}

.picture {
    margin-bottom: 16px;
    min-height: 260px;
    width: 100%;
    border-radius: 6px;
    background-repeat: no-repeat;
    background-size: cover;
}

.leftWrapper {
    width: 280px;
    max-width: 100%;
    text-align: center;
}

.rightWrapper {
    padding-left: 24px;
    width: 800px;
    max-width: 100%;
}

.title {
    margin-bottom: 12px;
}

.back {
    padding-left: 0;
    margin-bottom: 12px;
}

.edit {
    margin-top: 12px;
}

@media (max-width: 540px) {
    .wrapper {
        flex-wrap: wrap;
        justify-content: center;
    }
}