.title {
    font-weight: 400;
    margin-bottom: 12px;
}

.loading {
    font-size: 44px;
    margin-bottom: 24px;
}

.wrapper {
    text-align: center;
    width: 100%;
}
.hint{
    color: grey;
}

.noSession{
    text-align: center;
    margin-top: 44px;
    font-weight: bold;
}