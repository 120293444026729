.header {
    background-color: white;
    min-height: 46px;
    height: 46px;
    // color: white;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: 1px solid #e6e6e6;

    a {
        color: #1f3a45;
    }
}

.inner {
    display: flex;
    align-items: center;
    width: 1100px;
    max-width: 100%;
    margin: auto;
    min-height: 46px;
    height: 46px;
}

.insightsLink {
    color: #1f3a45;

    &:hover {
        color: #1f3a45;
    }
}

.left {
    flex-grow: 1;
    display: flex;
}

.right {
    display: flex;
    align-items: center;
}

.menu {
    line-height: 44px;
    min-width: 290px;
    margin-left: 24px;

    a {
        text-decoration: none;
    }
}