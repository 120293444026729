.wrapper {
    display: flex;
    align-items: center;
    color: #1f3a45;
    a {
        color: #1f3a45;
    }
}


.logoText {
    margin-left: 6px;
    font-size: 20px;
    text-decoration: none;
    font-weight: 700;
    color: #1f3a45;
}
.pic{
    border-radius: 4px;
    overflow: hidden;
}
.logoWhite {
    color: white;
}

.logoText {
    margin-left: 8px;
    font-size: 20px;
    font-weight: bold;
    font-family: 'silka', sans-serif;
}

.logoLink {
    display: flex;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    color: #1f3a45;
    font-weight: bold;

    &:hover {
        color: #1f3a45;
    }
}