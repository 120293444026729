.archiveHint {
    font-size: 14px;
    color: grey;
    text-align: center;
    margin-top: 6px;
    padding: 2px;

}

.archiveWrapper {
    margin-top: 12px;
    display: flex;
    align-items: center;
}

.archiveBtn {
    margin-bottom: 0;
    margin-top: 6px;
    margin-right: 12px;
    max-width: 260px;
}

.confirmReward {
    max-width: 100%;
}

@media (max-width: 600px) {
    .archiveWrapper {
        flex-wrap: wrap;
    }

    .archiveBtn,
    .archiveHint {
        min-width: 300px;
        max-width: 100%;
    }

    .archiveBtn {
        margin-right: 0px;

    }
}